import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { env, isIOS } from '../config';
import logo from '../../assets/images/logo_standard.png';
import iconOnline from '../../assets/images/icon_online.svg';
import iconOffline from '../../assets/images/icon_offline.svg';
import sync from '../sync';


function exportLogs(e) {
  try {
    e.preventDefault();
    window.webkit.messageHandlers.shareLogData.postMessage("");
  }
  catch(err) {
    /* for debugging in browser */
    console.log('export logs button clicked');
  }
}

function logout(username) {
  return (
    <div id="header-right-wrapper">
      {/* for debugging in browser, comment out "isIOS &&" */}
      {isIOS && <div id="export-logs">
        <button onClick={exportLogs}>Export Logs</button>
      </div>}
      <div id="nav-user">
        <div id="nav-user-text">
          You are logged in as
          <div id="nav-username">{username}</div>
        </div>
        <Link to="/logout" id="nav-logout" className="nav-link">
          Logout
        </Link>
      </div>
    </div>
  );
}


function getOnlineState(isOnline) {
  const reload = (e) => {
    e.preventDefault();
    sync
      .checkNetwork(true)
      .then(() => {
        window.mobileLog("Header.getOnlineState: starting manual sync");
        sync.run(true, true);
        window.location.reload();
      })
      .catch((err) => {
        window.mobileLog("Header.getOnlineState: failed sync check");
        alert("Online state not detected, I can't sync here");
      });
  };
  const icon = isOnline ? iconOnline : iconOffline;
  const onlineState = isOnline ? 'online' : 'offline';

  // battling react with linebreaks will be th death of me, no <br /> it is...
  const offlineButton = "the \"(!)\u00A0Offline\" button";

  return (
    <div id="nav-sync">
    <a
      href="#"
      id="nav-online-state"
      className={`nav-link ${onlineState}`}
      onClick={(e) => {
        e.preventDefault();
        sync.run(true, true);
      }}
    >
      <img id="nav-online-state-icon" src={icon} />
      {onlineState}
    </a><span id="manual-sync-note">
        A manual sync is required. When you are confident you have
        connectivity, tap<br />{offlineButton} on the left.
      </span>
    </div>
  );
}

function getStagingHeader() {
  return (
    <div id="staging-header">
      This is the Testing Environment — do not use for actual inspections!
    </div>
  );
}

function getAuthHeader(isOnline, username, userIsAdmin, location, highContrastToggle) {
  const hasActiveClass = (path) => {
    if (location.indexOf(path) >= 0) {
      return 'active';
    }
  };

  return (
    <div id="nav-wrap">
      <div id="nav-logo">
        <img id="nav-logo-img" src={logo} />
      </div>
      {getOnlineState(isOnline)}
      <div id="nav-links">
        {isIOS || (
          <Fragment>
            <Link className={`nav-link ${hasActiveClass('/view')}`} to="/">
              Scores
            </Link>
            <Link
              className={`nav-link ${hasActiveClass('/reports')}`}
              to="/reports"
            >
              Reports
            </Link>
            {userIsAdmin && (
              <a className="nav-link" href="/admin">
                Admin
              </a>
            )}
          </Fragment>
        )}
        {isIOS && highContrastToggle}
      </div>
      {username && logout(username)}
    </div>
  );
}

export default function Header(props) {
  const [isHighContrast, setIsHighContrast] = useState(
    document.body.classList.contains('high-contrast')
  );

  const highContrastToggle = () => {
    const handleToggleClick = () => {
      if (isHighContrast) {
        document.body.classList.remove('high-contrast');
        setIsHighContrast(false);
      } else {
        document.body.classList.add('high-contrast');
        setIsHighContrast(true);
      }
    };

    return (
      <div className="toggle-container">
        <span>High Contrast</span>
        <input
          className="toggle-switch toggle-switch-on-off"
          type="checkbox"
          id="contrast-toggle"
          checked={isHighContrast}
          onChange={handleToggleClick}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <nav id="navbar" className={props.isOnline ? 'online' : 'offline'}>
        {props.authorized
          ? getAuthHeader(
              props.isOnline,
              props.username,
              props.userIsAdmin,
              props.location,
              highContrastToggle()
            )
          : getOnlineState(props.isOnline)}
      </nav>
      {env === 'staging' && getStagingHeader()}
    </Fragment>
  );
}

Header.propTypes = {
  username: PropTypes.string.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  history: PropTypes.object,
  authorized: PropTypes.bool,
  location: PropTypes.string,
};
