import React from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { requestInterceptor, errorResponseInterceptor } from './interceptors';
import { axios } from './config';
import sync from './sync';
import App from './containers/App';

window.toggleOffline = () => {
  try {
    sync.isOnline = !sync.isOnline;

    // for some reasone the value of sync.isOnline seems to behave as
    // if it were called sync.isOffline. I'm not sure why it's doing
    // that
    return sync.isOnline;
  } catch (er) {
    console.log(er);
    console.log('sych simulation failure');
  }
};

window.getOfflineState = () => {
  return sync.isOnline;
}

// These hook into AJAX requests and responses to perform
// actions necessary for synchronization and authentication
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(
  (response) => response,
  errorResponseInterceptor
);

const root = document.createElement('app');

render(
  <Router>
    <App />
  </Router>,
  root
);

document.body.insertBefore(root, document.body.firstElementChild);
